import revive_payload_client_Frd8gASYWa from "/home/runner/work/onboard/onboard/Code/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/home/runner/work/onboard/onboard/Code/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/home/runner/work/onboard/onboard/Code/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/home/runner/work/onboard/onboard/Code/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/home/runner/work/onboard/onboard/Code/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_aQoh9E5uaZ from "/home/runner/work/onboard/onboard/Code/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/onboard/onboard/Code/app/src/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/home/runner/work/onboard/onboard/Code/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_kCUITUWXUN from "/home/runner/work/onboard/onboard/Code/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apiClient_zyEEH8uBAU from "/home/runner/work/onboard/onboard/Code/app/src/plugins/apiClient.ts";
import appFlashMessage_ZFywa6CIC6 from "/home/runner/work/onboard/onboard/Code/app/src/plugins/appFlashMessage.ts";
import appLoading_xdjaxWlHEA from "/home/runner/work/onboard/onboard/Code/app/src/plugins/appLoading.ts";
import i18n_VfGcjrvSkj from "/home/runner/work/onboard/onboard/Code/app/src/plugins/i18n.ts";
import logRocket_client_QVgPwO1Krv from "/home/runner/work/onboard/onboard/Code/app/src/plugins/logRocket.client.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/onboard/onboard/Code/app/src/plugins/sentry.client.ts";
import vueScrollTo_Ln83f0rtOo from "/home/runner/work/onboard/onboard/Code/app/src/plugins/vueScrollTo.ts";
import vuetify_7h9QAQEssH from "/home/runner/work/onboard/onboard/Code/app/src/plugins/vuetify.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  check_outdated_build_client_yxgjJ9XxnV,
  plugin_vue3_aQoh9E5uaZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  chunk_reload_client_kCUITUWXUN,
  apiClient_zyEEH8uBAU,
  appFlashMessage_ZFywa6CIC6,
  appLoading_xdjaxWlHEA,
  i18n_VfGcjrvSkj,
  logRocket_client_QVgPwO1Krv,
  sentry_client_shVUlIjFLk,
  vueScrollTo_Ln83f0rtOo,
  vuetify_7h9QAQEssH
]